<template>
  <CModal :show.sync="show" size="xl" addContentClasses="card" :closeOnBackdrop="false">
    <template slot="header-wrapper">
      <CCardHeader>
        <CIcon name="cil-image1" />
        {{titulo}}
        <CButtonClose @click="fecharVisualizacao()" buttonClasses="text-muted close" />
      </CCardHeader>
    </template>
    <template slot="body-wrapper">
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CRow>
          <CCol sm="12">
            <div ref="vizualizador" id="visualizadorSVG"></div>
          </CCol>
        </CRow>
      </CCardBody>
    </template>
    <template slot="footer-wrapper">
      <CCardFooter>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click="gerarPlantaDxf(estruturalID)"
        >
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar DXF
        </CButton>
      </CCardFooter>
    </template>
  </CModal>
</template>
<script>
import mixin from "./../../shared/mixin";
import mixinEstrutural from "./mixinsEstrutural";
import Axios from "axios";
import Visualizacao from "@/Plugin/Desenho/visualizacao";
export default {
  name: "EstruturaVisualizacao",
  mixins: [mixin, mixinEstrutural],
  props: {
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      requisicaoAberta: false,
      titulo: "",
      estruturalID: null
    };
  },
  methods: {
    fecharVisualizacao() {
      this.$eventoHub.$emit("fecharJanelaVisualizacao", []);
    },
    obtenhaDesenhoEstrutural(estruturalID) {
      this.estruturalID = estruturalID;
      let projetoID = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      Axios.get(`/projeto/${projetoID}/estrutural/${estruturalID}/desenho/sb`)
        .then(resposta => {
          Visualizacao.ParseDesenho(resposta.data.retorno.desenho);
          Visualizacao.zoomExtend();
        })
        .catch(error => {})
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    gerarPlantaDxf(estruturalID) {
      let projetoID = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      Axios.get(`/projeto/${projetoID}/estrutural/${estruturalID}/desenho/dxf`)
        .then(resposta => {
          if (resposta.status == 200) {
            let arquivo = new File(
              [resposta.data.retorno.conteudo],
              resposta.data.retorno.arquivo,
              { type: "application/dxf" }
            );
            let urlDownload = URL.createObjectURL(arquivo);
            let a = document.createElement("a");
            a.href = urlDownload;
            a.download = resposta.data.retorno.arquivo;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(urlDownload);
            a.remove();
          }
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  mounted() {
    Visualizacao.informarContainer(this.$refs.vizualizador);
  },
  created() {
    this.$eventoHub.$on("exibirPlanta", this.obtenhaDesenhoEstrutural);
  },
  destroyed() {
    this.$eventoHub.$off("exibirPlanta", this.obtenhaDesenhoEstrutural);
  }
};
</script>
<style>
#visualizadorSVG {
  height: 75vh;
}
</style>
